
.main-content {
    &, & * {
      box-sizing: border-box;
    }
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .main-menu {
        padding-top: 20px;
        width: 210px;
        height: 100%;
        ul,li {
          padding: 0;
          margin: 0;
          list-style: none;
        }

        ul.menu-list {
            .menu-item {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                  text-decoration: none;
                  color: #666
                }

            }
        }
        li.active {
          background-color: #d9ecff;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 100%;
            top: 0;
            left: 0px;
            background-color:  #337ecc;
          }
          .menu-item a {
            color: #337ecc;
          }
        }
    }
    .main-body {
        flex: 1;
        height: 100%;
        background-color: #F6F8F9;
        .body-content {
            margin: 20px;
            background-color: #FFFFFF;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            .el-menu-com {
                padding: 10px 10px 0 10px;
            }
        }
    }
;
}


