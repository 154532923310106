.pd-10 {
    padding: 10px;
}
.h-calc-60 {
    height: calc(100% - 60px);
}
.boom-compo {
    background-color: transparent;
    border: none;
    padding: 10px;
    &, & * {
        box-sizing: border-box;
    }
    .el-notification__content {
        color: #EFF6FF;
        font-weight: bold;
        font-size: 18px;
    }
}
