
    .header-info {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 2px 5px 0px rgba(15, 26, 54, 0.05);
        &, & * {
            box-sizing: border-box;
        }
        .user-info {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 40px;
            .nick-name {
              font-size: 16px;
              font-weight: bold;
              color: #333;
              margin: 0 10px;
            }
        }
        .site-name {
            padding-left: 40px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
        }
    }
    .main {
        width: 100%;
        height: calc(100vh - 60px);
    }
